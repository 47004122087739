import React from 'react';
import questionsAndAnswers from './aboutQuestionsAndAnswers.json';
import QuestionAndAnswer from './QuestionAndAnswer';

const About = () => {
    const questionAnswerPairList = questionsAndAnswers.map(qa => <QuestionAndAnswer key={qa.id} question={qa.question} answer={qa.answer} />);

    return(
        <div className='About cb-narrow-content'>
            <h1 className='pb-3'>About</h1>
            { questionAnswerPairList }
        </div>
    );
}

export default About;