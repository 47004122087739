import React from 'react'
import Button from 'react-bootstrap/Button';
import PopupBase from './PopupBase';
import './Popup.css';

function Popup(props) {
    const title = props.title ?? "Title";
    const bodyText = props.bodyText ?? "This is a popup."
    const closeBtnText = props.closeBtnText ?? "Close";

    const body = <p>{bodyText}</p>;
    const closeButton = <Button onClick={() => { props.setTrigger(false) }} size="sm">{closeBtnText}</Button>

    return (props.trigger) ? (
        <PopupBase title={title} body={body} closeButton={closeButton}/>
  ) : "";
}

export default Popup