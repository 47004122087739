import React from 'react';
import './Home.css';
import avatarImage from '../../resources/icons/cb_avatar_logo.svg';

const Home = () => {
    return(
        <div className='Home h-100 d-flex flex-column justify-content-center align-items-center pb-5'>
            <div className='logo-container p-5'>
                <img src={avatarImage} alt='logo' />
                <div className='spinning-border'></div>
            </div>
            <h1 className='mt-5'>Hello, I'm Chris.</h1>
            <h6 className='text-center'>I'm a <span className="text-primary">web developer</span>. Use the navbar at the top to explore my website.</h6>
        </div>
    );
}

export default Home;