import React from 'react';

const QuestionAndAnswer = (params) => {
    return (
        <div className='pb-3'>
            <h5>{params.question}</h5>
            <p>{params.answer}</p>
        </div>
    );
}

export default QuestionAndAnswer;