import React from 'react'
import Card from 'react-bootstrap/Card'
import './Popup.css';

function PopupBase(props) {
    const title = props.title ?? "Title";

    return (
        <div className='popup'>
            <Card className='popup-inner'>
                <Card.Header><h4 className='mb-0'>{title}</h4></Card.Header>
                <Card.Body>{props.body}</Card.Body>
                <Card.Footer><div className='d-flex flex-row-reverse'>{ props.addButton }{ props.closeButton }</div></Card.Footer>
            </Card>
        </div>
  );
}

export default PopupBase