import React from 'react';
import './Music.css';
import musicAbout from './MusicAbout.json';
import LinkBox from './LinkBox';
import Accordion from 'react-bootstrap/Accordion';
import AccordionBody from 'react-bootstrap/AccordionBody';
import AccordionHeader from 'react-bootstrap/AccordionHeader';
import AccordionItem from 'react-bootstrap/AccordionItem';

const Music = () => {
    const musicText = musicAbout.map(item => <div key={item.id} className='mb-1'><h5>{item.header}</h5><p>{item.body}</p></div>);

    return (
        <div className='Music'>
            <h1 className='mb-3'>Music</h1>

            <div className='cb-narrow-content justify-content-center'>
                <div className='flexbox-item mb-3'>
                    <h5>Links for Listening</h5>
                    <p>For your convenience, I have placed the links right here at the top. Simply, expand the music provider name of your choice below and a playlist will appear. For more information about the music, scroll down past the links and keep reading.</p>
                    <Accordion className='flexbox-item'>
                        <AccordionItem eventKey='0'>
                            <AccordionHeader>
                                <h5>Spotify</h5>
                            </AccordionHeader>

                            <AccordionBody>
                                <LinkBox title="Spotify" link="https://open.spotify.com/embed/artist/4yf5MFMYosAmejT3VdQMiX?utm_source=generator" metaTitle="spotify-player" />
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem eventKey="1">
                            <AccordionHeader>
                                <h5>YouTube</h5>
                            </AccordionHeader>

                            <AccordionBody>
                                <LinkBox title="YouTube" link="https://www.youtube.com/embed/videoseries?list=OLAK5uy_lZyeVAhC5A7zJHhxzqhCAw8BaZ1Kl16qQ" metaTitle="YouTube-player" />
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>

                <div className='flexbox-item'>{musicText}</div>
            </div>
        </div>
    );
}

export default Music;