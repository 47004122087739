import { React, useState } from "react";
import helpText from './HelpText.json';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Popup from "../common/Popup/Popup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFileText } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
    // TODO: I need to actually hook this component up to a microservice that will send me an email upon request. When creating this
    // component, my assumption was that I'd be hosting everything on AWS.
    const explainerText = helpText.explainerText;
    const popupText = helpText.popupText;
    const [isPopupActive, setIsPopupActive] = useState(false);

    return (
        <div className="cb-narrow-content">
            <Popup trigger={isPopupActive} setTrigger={ setIsPopupActive } title="Not Available" bodyText={popupText}></Popup>
            <h1 className="text-center mb-3">Contact Form</h1>
            <p className="mb-4 text-muted"><span className='warning-text'>Warning: </span>{explainerText}</p>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><FontAwesomeIcon className="text-secondary" icon={faEnvelope} />&nbsp;Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label><FontAwesomeIcon className="text-secondary" icon={faFileText} />&nbsp;Message</Form.Label>
                    <Form.Control as="textarea" placeholder="Please type your message here." />
                </Form.Group>

                <Button variant="primary" onClick={() => { setIsPopupActive(true) }}>
                    Submit
                </Button>
            </Form>
        </div>
    );
}

export default Contact;