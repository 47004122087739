import React from 'react';

const LinkBox = (props) => {
    return (
        <iframe src={props.link} width="100%" height="380" frameBorder="0" allowFullScreen="" 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title={props.metaTitle}>
        </iframe>
    );
}

export default LinkBox;