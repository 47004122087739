import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Component Imports
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Music from './components/Music/Music';

function App() {
  return (
    <Router>
      <div className='App'>
        <Header />
        <div className='BodyWrapper p-3'>
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route path="/about"><About /></Route>
            <Route path="/contact"><Contact /></Route>
            <Route path="/music"><Music /></Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
